import React from 'react';
import { FaHeart } from 'react-icons/fa';

const Footer = () => (
  <div className="flex items-center justify-center mt-16 mb-10">
    <FaHeart />
  </div>
);

export default Footer;
